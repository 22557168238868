import React, { useCallback, useMemo, useState, useRef } from "react";
import Form, { GroupItem, SimpleItem, Label, ButtonItem, RequiredRule } from "devextreme-react/form";
import { Popup } from "devextreme-react/popup";
import { ScrollView } from "devextreme-react/scroll-view";
import ValidationSummary from "devextreme-react/validation-summary";
import Toast from 'components/Toast';

const AssetTypeCreate = ({ showPopUp, setShowPopUp, sendDataToParent }) => {
  const [type, setType] = useState('');
  const [attributes, setAttributes] = useState(['']);
  const scrollViewRef = useRef(null);
  const formRef = useRef(null);
  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: "info",
    message: "",
    displayTime: 3000,
  });

  const generateNewAttributeOptions = useCallback(
    (index) => ({
      buttons: [
        {
          name: "trash",
          location: "after",
          options: {
            stylingMode: "text",
            icon: "trash",
            onClick: () => {
              const newAttributes = attributes
                .slice(0, index)
                .concat(attributes.slice(index + 1));
              setAttributes(newAttributes);
            },
          },
        },
      ],
    }),
    [attributes]
  );

  function manageContentScroll() {
    if (scrollViewRef.current) {
      const scrollViewInstance = scrollViewRef.current.instance;
      scrollViewInstance.scrollTo({ top: scrollViewInstance.scrollHeight() });
    }
  }

  const AttributeOptions = useMemo(() => {
    const options = [];
    for (let i = 0; i < attributes.length; i += 1) {
      options.push(generateNewAttributeOptions(i));
    }
    return options;
  }, [attributes, generateNewAttributeOptions]);

  const AttributeButtonOptions = useMemo(
    () => ({
      icon: "add",
      text: "Add Attribute",
      color: '#b51716',
      onClick: () => {
        setAttributes((prevAttributes) => [...prevAttributes, ""]);
        manageContentScroll();
      },
    }),
    []
  );

  const handleSave = (e) => {
    const formInstance = formRef.current.instance;

    // Validate the form
    const validationResult = formInstance.validate();
    if (!validationResult.isValid) {
      // Validation failed, show error and stop submission
      return;
    }

    // Filter out empty attributes
    const filteredAttributes = attributes.filter((attr) => attr.trim() !== "");
    const formData = {
      type: type,
      attributes: filteredAttributes,
    };

    // sending data to parent component
    sendDataToParent(formData);

  };

  const handleCancel = () => {
    setShowPopUp(false); // Close popup on cancel
  };

  return (
    <>
    <Toast
    setParentToastConfig={setToastConfig}
    toastBody={toastConfig}
  />
    <React.Fragment>
      <Popup
        visible={showPopUp}
        width={500}
        height={700}
        title={"Add Asset Type"}
        showCloseButton={true}
        onHiding={handleCancel} // Close popup when 'X' is clicked
      >
          <div className="popup-content" style={{ height: "calc(100% - 60px)" }}> {/* Adjust height of content */}

        <ScrollView height={400} showScrollbar="always" ref={scrollViewRef}>
          <Form
            ref={formRef}
            formData={{ Type: type, Attributes: attributes }}
            validationGroup="assetTypeForm"
            // onFieldDataChanged={(e) => {
            //   if (e.dataField === "Type") {
            //     setType(e.value);
            //   }
            // }}
            // showValidationSummary={true}
          >
            {/* <ValidationSummary
              validationGroup="assetTypeForm"
              showGeneralErrors={false} // To hide general errors if any
            /> */}
            <GroupItem>
              <SimpleItem dataField="Type" 
                editorOptions={{
                  onValueChanged: (e) => setType(e.value),
                  validationMessageMode: "always", // Show validation only when triggered
                }}>
                <Label text="Asset Type Name" />
                <RequiredRule message="Asset Type Name is required" />
              </SimpleItem>
            </GroupItem>

            <GroupItem caption="Attributes">
              {AttributeOptions.map((attribute, index) => (
                <SimpleItem
                  key={`Attributes${index}`}
                  dataField={`Attributes[${index}]`}
                  editorOptions={attribute}
                >
                  <Label text={`Attribute ${index + 1}`} />
                </SimpleItem>
              ))}
              <ButtonItem
                horizontalAlignment="left"
                cssClass="add-attrib-button"
                buttonOptions={AttributeButtonOptions}
              />
            </GroupItem>
          </Form>
        </ScrollView>
        </div>
        <div className='movePopup' style={{ display: "flex", justifyContent: "flex-end", marginBottom: "20px" }}>
            <button className='movePopupSaveBtn' style={{cursor:"pointer", fontWeight: "bold", marginRight: "5px"}} onClick={handleSave}>Save</button>
            <button className='movePopupSaveBtn' useSubmitBehavior={true} style={{cursor:"pointer", fontWeight: "bold"}} onClick={handleCancel} >Cancel</button>
         </div>
      </Popup>
    </React.Fragment>
    </>
  );
};

export default AssetTypeCreate;
